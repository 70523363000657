'use client';
import React, { useEffect, useMemo, useState } from 'react';
import { isImageLoaded, simplifyNumber, toast } from '@/utils';
import { PayoutPack } from '@/model/Payout';
import { cn } from '@/utils';
import Button from '@/components/ui/Button';
import OfferPayout from '@/components/OfferPayout';
import LockIcon from '/public/assets/icons/lock.svg';
import LazyImage from '@/components/LazyImage';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';
import Image from 'next/image';

/**
 *
 * @param {{pack: PayoutPack}} param0
 * @returns
 */
function PackCard({ pack, openPurchaseModal, isStoreOpen }) {
  const isActive = pack.isActive();

  const handleClick = () => {
    if (!isActive) {
      toast({
        description: `This pack is not available at the moment`,
        status: 'error',
      });
      return;
    }
    openPurchaseModal({ pack });
  };

  const TOAST_MSG =
    LANGUAGES_CONTENT[StorageService.getLanguage()]['toast'].redeem.pack;

  return (
    <div
      className={cn(
        'relative flex flex-col justify-center gap-1 items-center bg-card-bg-color rounded-lg overflow-hidden',
        !isActive && 'opacity-50'
      )}
      onClick={handleClick}
    >
      <PackImage pack={pack} />
      <div className="flex flex-col w-full h-full p-4">
        <p className="font-medium text-left">{pack.name}</p>
        <p className={'text-xl font-semibold text-left mb-2'}>
          {pack.payout.currency.symbol}{' '}
          {simplifyNumber(pack.payout.amount, 10000)}
        </p>
        <Button
          disabled={!isStoreOpen}
          className={
            'w-[100%] mt-auto bg-gradient-to-r from-[var(--blue-7)] to-[var(--blue-4)]'
          }
          size={'3'}
        >
          {!isActive && (
            <LockIcon className="relative block [&_path]:fill-white" />
          )}
          <OfferPayout amount={pack.selling.amount} size="medium" />
        </Button>
      </div>
    </div>
  );
}

export default PackCard;

const hashString = str => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

export const getRandomDefaultIcon = name => {
  const defaults = [
    '/assets/images/default/giftcard1.png',
    '/assets/images/default/giftcard2.png',
    '/assets/images/default/giftcard3.png',
  ];
  const index = Math.abs(hashString(name)) % defaults.length;
  return defaults[index];
};

/**
 *
 * @param {Object} props
 * @param {PayoutPack} props.pack
 * @returns
 */

const PackImage = ({ pack }) => {
  const [imageError, setImageError] = useState(false);

  const icon = useMemo(() => {
    if (!pack) return null;
    return pack.getIconUrl();
  }, [pack]);

  useEffect(() => {
    if (!icon) return;
    checkImkage();
  }, [icon]);

  async function checkImkage() {
    try {
      await isImageLoaded(icon);
    } catch (error) {
      setImageError(true);
    }
  }

  return (
    <img
      alt="pack-icon"
      src={imageError ? pack.getDefaultIcon() : icon}
      className={'relative block object-contain w-full aspect-[1.5]'}
    />
  );
};
